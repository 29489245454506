import React, { useState, useEffect, useContext } from "react";
import { graphql, Link } from "gatsby";
import { flow, get, sortBy } from "lodash/fp";
import styled, { ThemeContext } from "styled-components";
import { StickyContainer, Sticky } from "react-sticky";
import { useMediaQuery } from "react-responsive";

import Head from "components/Head";
import Media from "components/product/Media";
import Form from "components/product/Form";
import MediaModal from "components/product/MediaModal";
import Upsell from "components/product/Upsell";

import { Container, Row, Col } from "components/Grid";

import Fade from "transitions/Fade";

const Description = styled.div`
  ${(p) => p.theme.typography.tNormal};
  margin: 20px 0 0 0;

  ${(p) => p.theme.media.minWidth("large")} {
    width: 85%;
  }
`;

const Collections = styled.div`
  margin-top: 30px;

  p {
    margin: 0 0 0.5em 0;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  a {
    text-decoration: underline;
  }
`;

const MediaCol = styled(Col)`
  ${(p) => p.theme.media.minWidth("tablet")} {
    padding-right: 0;
  }
`;

const DetailCol = styled(Col)`
  ${(p) => p.theme.media.minWidth("tablet")} {
    padding-left: 0;
  }
`;

const Product = ({ pageContext, ...props }) => {
  const product = flow(get("data.shopify.nodes[0]"))(props);

  const [mounted, setMounted] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);

  const theme = useContext(ThemeContext);

  useEffect(() => {
    setMounted(true);
  }, [setMounted]);

  useEffect(() => {
    theme.global.setHeaderPadding(true);
  }, [theme.global]);

  const curated = flow(
    get("data.curated.nodes"),
    sortBy((n) => pageContext.curatedHandles.indexOf(n.handle))
  )(props);

  const paired = flow(
    get("data.paired.nodes"),
    sortBy((n) => pageContext.pairedHandles.indexOf(n.handle))
  )(props);

  const recommended = flow(
    get("data.recommended.nodes"),
    sortBy((n) => pageContext.recommendedHandles.indexOf(n.handle))
  )(props);

  const onMediaImageClick = (index) => {
    setModalOpen(true);
    setModalIndex(index);
  };

  const onModalCloseClick = () => {
    setModalOpen(false);
    setModalIndex(0);
  };

  const renderMediaModal = () => {
    if (product.images.length === 0) return;

    return (
      <MediaModal
        open={modalOpen}
        index={modalIndex}
        onCloseClick={onModalCloseClick}
        {...product}
      />
    );
  };

  const designerCollection = product.collections.find(
    (c) => c.title === product.vendor
  );

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const productDescription =
    product?.metafields?.find((v) => v.key === "description_tag")?.value ||
    product?.description;
  const productTitle =
    product?.metafields?.find((v) => v.key === "title_tag")?.value ||
    product.title;
  const productImage = get("images[0].gatsbyImageData.images.fallback.src")(
    product
  );

  return (
    <Fade>
      <Head title={productTitle} description={productDescription} image={productImage} />
      <StickyContainer>
        <Row>
          <MediaCol tb={12}>
            <Media
              {...product}
              onImageClick={onMediaImageClick}
              isGallery={mounted && isMobile}
            />
          </MediaCol>
          <DetailCol tb={12}>
            {!isMobile ? (
              <Sticky>
                {({ style }) => (
                  <Row style={style}>
                    <Col
                      tb={22}
                      tbPush={1}
                      dk={20}
                      dkPush={2}
                      lg={18}
                      lgPush={3}
                      xxl={16}
                      xxlPush={4}
                    >
                      <Form product={product} designer={designerCollection} />
                      <Container fullBleedTablet={true}>
                        <Description>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: product.descriptionHtml,
                            }}
                          />
                          {designerCollection && (
                            <Collections>
                              <p>View More:</p>
                              <ul>
                                <li>
                                  <Link
                                    to={`/collections/${designerCollection.handle}`}
                                  >
                                    {designerCollection.title}
                                  </Link>
                                </li>
                              </ul>
                            </Collections>
                          )}
                        </Description>
                      </Container>
                    </Col>
                  </Row>
                )}
              </Sticky>
            ) : (
              <Row>
                <Col
                  tb={22}
                  tbPush={1}
                  dk={20}
                  dkPush={2}
                  lg={18}
                  lgPush={3}
                  xxl={16}
                  xxlPush={4}
                >
                  <Form product={product} designer={designerCollection} />
                  <Container fullBleedTablet={true}>
                    <Description>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: product.descriptionHtml,
                        }}
                      />
                      {designerCollection && (
                        <Collections>
                          <p>View More:</p>
                          <ul>
                            <li>
                              <Link
                                to={`/collections/${designerCollection.handle}`}
                              >
                                {designerCollection.title}
                              </Link>
                            </li>
                          </ul>
                        </Collections>
                      )}
                    </Description>
                  </Container>
                </Col>
              </Row>
            )}
          </DetailCol>
        </Row>
      </StickyContainer>
      <Upsell curated={curated} paired={paired} recommended={recommended} />
      {renderMediaModal()}
    </Fade>
  );
};

export const query = graphql`
  query ProductQuery(
    $productId: String!
    $curatedHandles: [String!]
    $pairedHandles: [String!]
    $recommendedHandles: [String!]
  ) {
    shopify: allShopifyProduct(filter: { storefrontId: { eq: $productId } }) {
      nodes {
        shopifyId
        storefrontId
        title
        handle
        productType
        vendor
        description
        descriptionHtml
        collections {
          title
          handle
        }
        images {
          id
          gatsbyImageData(layout: FULL_WIDTH)
        }
        tags
        options {
          id
          name
          position
          values
        }
        variants {
          shopifyId
          storefrontId
          price
          compareAtPrice
          selectedOptions {
            name
            value
          }
        }
      }
    }
    curated: allShopifyProduct(filter: { handle: { in: $curatedHandles } }) {
      nodes {
        ...Product
      }
    }
    paired: allShopifyProduct(filter: { handle: { in: $pairedHandles } }) {
      nodes {
        ...Product
      }
    }
    recommended: allShopifyProduct(
      filter: { handle: { in: $recommendedHandles } }
    ) {
      nodes {
        ...Product
      }
    }
  }
`;

export default Product;
