import React, { useContext, useState, useCallback, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Breakpoint } from "react-socks";

import { map, fromPairs, flow } from "lodash/fp";

import { H2, H4 } from "components/Typography";

import Dropdown from "components/Dropdown";

import { ButtonPrimary } from "components/Button";

import { Container } from "components/Grid";

import { getPrice, hexFromColorOption } from "utils/product";

import { format, zonedTimeToUtc } from "date-fns-tz";
import StoreContext from "../../context/Store";

const Vendor = styled(H2)`
  margin: 0;

  ${(p) => p.theme.media.minWidth("tablet")} {
    margin: 0 0 10px 0;
  }
`;

const Title = styled(H4)`
  margin: 0;

  ${(p) => p.theme.media.minWidth("tablet")} {
    margin: 0 0 12px 0;
  }
`;

const VendorPrice = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px 0;
`;

const VendorTitlePrice = styled.div`
  margin: 0 0 12px 0;

  ${(p) => p.theme.media.minWidth("tablet")} {
    margin: 30px 0 0 0;
  }

  ${(p) => p.theme.media.minWidth("desktop")} {
    margin: 75px 0 0 0;
  }

  ${(p) => p.theme.media.minWidth("large")} {
    margin: 100px 0 0 0;
  }

  ${(p) => p.theme.media.minWidth("xlarge")} {
    margin: 120px 0 0 0;
  }
`;

const PriceWrapper = styled.div`
  visibility: ${(p) => (p.$visible ? "visible" : "hidden")};
  ${(p) => p.theme.typography.tLarge}
  margin: 0;
`;

const Price = styled.span`
  display: inline-block;
`;

const CompareAtPrice = styled.span`
  display: inline-block;
  margin: 0 10px 0 0;
  text-decoration: line-through;
`;

const Swatch = styled.div`
  margin: 0 0 15px 0;

  ${(p) => p.theme.media.minWidth("tablet")} {
    margin: 20px 0;
  }
`;

const SwatchTitle = styled.div`
  ${(p) => p.theme.typography.tNormal};
  margin: 0 0 6px 0;
`;

const SwatchLabel = styled.label`
  display: block;
  border: solid 1px transparent;
  padding: 2px;

  &:after {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    background-color: ${(p) => p.hex};
  }
`;

const SwatchItems = styled.div`
  position: relative;
`;

const SwatchItem = styled.div`
  display: inline-block;
  margin: 0 4px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  input {
    display: none;

    &:checked + ${SwatchLabel} {
      border-color: ${(p) => p.theme.colors.white};
    }

    &:disabled + ${SwatchLabel} {
      opacity: 0.2;
    }
  }
`;

const Select = styled.div`
  margin: 0 0 15px 0;

  ${(p) => p.theme.media.minWidth("tablet")} {
    display: ${(p) => (p.isProductDisabled ? "block" : "flex")};
    height: 36px;
    margin: 25px 0;
  }
`;

const DropdownWrapper = styled.div`
  flex: 0 0 50%;
  margin: 0 0 10px 0;
`;

const DropdownWrapperInner = styled.div`
  ${(p) => p.theme.media.minWidth("tablet")} {
    margin-right: ${(p) => (p.isSoloSizeSelect ? 7 : 0)}px;
  }
`;

const Timestamp = styled.div`
  width: 100%;
  flex: 1;
  margin-top: 20px;
`;
const FinalSale = styled.p`
  margin-top: 20px;
  margin-bottom: 0;
  font-weight: 600;
`;

const AddToBag = styled(ButtonPrimary)`
  width: 100%;

  ${Select} & {
    flex: 1 0 50%;
  }
`;

const ProductForm = ({ product: initialProduct, designer }) => {
  const {
    client,
    addVariantToCart,
    addingVariantStatus,
    setMiniCartOpen,
  } = useContext(StoreContext);

  // Not for online sale
  const isNFOS = initialProduct.tags && initialProduct.tags.includes("NFOS");
  const availableTimestamp = initialProduct?.metafields?.filter(
    (metafield) => metafield?.key === "availabletimestamp"
  )?.[0]?.value;

  const isProductDisabled =
    zonedTimeToUtc(availableTimestamp, "America/New_York") >
    zonedTimeToUtc(new Date(), "America/New_York");
  const isFinalSale =
    initialProduct.tags && initialProduct.tags.includes("Final Sale Item");
  const {
    variants: [initialVariant],
  } = initialProduct;
  const [variant, setVariant] = useState({ ...initialVariant });
  const [product, setProduct] = useState({ ...initialProduct });
  const [selectedOptions, setSelectedOptions] = useState(
    initialVariant.selectedOptions
  );

  const sizeOptions = product.options.filter((o) => !!o.name.match(/size/i));

  const fetchProduct = useCallback(() => {
    client.product
      .fetch(initialProduct.storefrontId)
      .then((product) => {
        if (product) {
          setProduct({ ...product });
        }
      })
      .catch((error) => console.log(error));
  }, [client.product, initialProduct.storefrontId]);

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  useEffect(() => {
    const selectedVariant = client.product.helpers.variantForOptions(
      product,
      optionsSnapshot()
    );

    setVariant({ ...selectedVariant });
  }, [selectedOptions, product]);

  const optionsSnapshot = (value, index) => {
    let _selectedOptions = [...selectedOptions];

    if (value) {
      _selectedOptions[index] = {
        ...selectedOptions[index],
        value,
      };
    }

    return flow(
      map((o) => [o.name, o.value]),
      fromPairs
    )(_selectedOptions);
  };

  const onOptionChange = (index, value) => {
    const options = [...selectedOptions];
    options[index].value = value;
    setSelectedOptions(options);

    fetchProduct();
  };

  const onAddClick = () => {
    variant.product = product;

    addVariantToCart(variant).then(() => {
      setMiniCartOpen(true);
    });
  };

  const renderCompareAtPrice = () => {
    
    if (!variant.compareAtPrice) return;

    const pcompareAtPrice = parseFloat(variant.compareAtPrice.amount);
    const pprice = parseFloat(variant.price.amount);

    if (pprice >= pcompareAtPrice) return;

    return <CompareAtPrice>{getPrice(variant.compareAtPrice.amount)}</CompareAtPrice>;
  };

  const renderPrice = () => {
    return (
      <PriceWrapper $visible={!!variant.price.amount}>
        {renderCompareAtPrice()}
        <Price>{getPrice(variant.price.amount)}</Price>
      </PriceWrapper>
    );
  };

  const renderSwatch = ({ id, name, values }, optionIndex) => {
    return (
      <Swatch key={id}>
        <SwatchTitle>
          {name}: {selectedOptions[optionIndex].value}
        </SwatchTitle>
        <SwatchItems>
          {values.map((v, i) => {
            const value = v.value || v;

            return (
              <SwatchItem key={i}>
                <input
                  id={`${name}-${value}`}
                  type="radio"
                  name={name}
                  value={value}
                  checked={
                    !!selectedOptions.find(
                      (o) => o.name === name && o.value === value
                    )
                  }
                  onChange={(e) => onOptionChange(optionIndex, e.target.value)}
                />
                <SwatchLabel
                  hex={hexFromColorOption(value)}
                  htmlFor={`${name}-${value}`}
                ></SwatchLabel>
              </SwatchItem>
            );
          })}
        </SwatchItems>
      </Swatch>
    );
  };

  const renderSelect = ({ id, name, values }, optionIndex) => {
    const options = values.map((v) => {
      const value = v.value || v;

      const variantSnapshot = client.product.helpers.variantForOptions(
        product,
        optionsSnapshot(value, optionIndex)
      );

      // console.log('variantSnapshot >>> ', variantSnapshot)

      let label = value;
      if (!variantSnapshot) {
        label += " - Unavailable";
      } else if (!variantSnapshot.available) {
        label += " - Sold Out";
      }

      return { label, value };
    });

    const isSoloSizeSelect = sizeOptions.length === 1 && name.match(/size/i);

    return (
      <>
        <Select key={id} isProductDisabled={isProductDisabled}>
          <DropdownWrapper>
            <DropdownWrapperInner isSoloSizeSelect={isSoloSizeSelect}>
              <Dropdown
                isSearchable={false}
                onChange={(v) => onOptionChange(optionIndex, v.value)}
                options={options}
                placeholder={`Select ${name}`}
                value={options.find(
                  (o) => o.value === selectedOptions[optionIndex].value
                )}
              />
            </DropdownWrapperInner>
          </DropdownWrapper>

          {!isProductDisabled && isSoloSizeSelect && !isNFOS ? (
            renderAdd()
          ) : (
            <div />
          )}
        </Select>
        {isProductDisabled && (
          <Timestamp>{`This item is launching on: ${format(new Date(availableTimestamp), "PPP (hh:mm a - zzzz)")}`}</Timestamp>
        )}
        {isFinalSale && <FinalSale>Item is FINAL SALE</FinalSale>}
      </>
    );
  };

  const renderOptions = () => {
    return product.options.map((option, i) => {
      if (option.name.match(/color/i)) {
        return renderSwatch(option, i);
      } else {
        return renderSelect(option, i);
      }
    });
  };

  const renderNfosMessage = () => {
    return (
      <p>
        In store only - please call <a href="tel:+19173883429">(917)388-3429</a>{" "}
        or email{" "}
        <a href="mailto:support@twomindsnyc.com">support@twomindsnyc.com</a>
      </p>
    );
  };

  const renderAdd = () => {
    if (!variant.available) return;

    let text = "Add to Bag";

    if (addingVariantStatus === "adding") text = "Adding...";

    if (addingVariantStatus === "success") text = "Added To Bag!";

    return (
      <AddToBag onClick={onAddClick} disabled={addingVariantStatus !== "ready"}>
        {text}
      </AddToBag>
    );
  };

  const renderVendor = () => {
    return (
      <Vendor>
        {designer && designer.handle ? (
          <Link to={`/collections/${designer.handle}`}>{product.vendor}</Link>
        ) : (
          product.vendor
        )}
      </Vendor>
    );
  };

  return (
    <>
      <Container fullBleedTablet={true}>
        <VendorTitlePrice>
          <Breakpoint mobile-large down>
            <>
              <VendorPrice>
                {renderVendor()}
                {renderPrice()}
              </VendorPrice>
              <Title as="h1">{product.title}</Title>
            </>
          </Breakpoint>

          <Breakpoint tablet up>
            <>
              {renderVendor()}
              <Title as="h1">{product.title}</Title>
              {renderPrice()}
            </>
          </Breakpoint>
        </VendorTitlePrice>
        {renderOptions()}

        {isNFOS && renderNfosMessage()}
      </Container>

      {sizeOptions.length !== 1 && !isNFOS && renderAdd()}
    </>
  );
};

export default ProductForm;