import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import gsap from 'gsap'
import Slider from 'react-slick'
import { Breakpoint } from 'react-socks'

const Modal = styled.div`
  ${p => p.theme.mixins.fill('fixed')};
  background-color: ${p => p.theme.colors.green};
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  z-index: 10;

  & [class*="breakpoint"] {
    height: 100%;
  }
`

const Scroll = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;  
  overflow-y: scroll;
  -webkit-overflow-scroll: touch;
`

const Carousel = styled(Slider)`
  margin: 0;
  height: 100%;

  .slick-list, .slick-track {
    height: 100% !important;
  }

  .slick-slide {
    height: 100%;
    border: none !important;
    > div {
      height: 100%;
    }
  }

  .slick-dots {
    ${p => p.theme.mixins.hCenter('absolute')};
    bottom: 20px;
    margin: 0;
    padding: 0;
    list-style: none;

    ${p => p.theme.media.minWidth('tablet')} {
      left: 30px;
      transform: none;
    }
  }  

  .slick-dots li {
    display: inline-block;
    margin: 0px;

    ${p => p.theme.media.minWidth('tablet')} {
      display: block;
    }
  }

  .slick-dots li button {
    position: relative;
    padding: 7px;
    display: block;    
    font-size: 0;
    color: transparent;

    &:after {
      display: block;
      content: '';
      width: 4px;
      height: 4px;
      background-color: ${p => p.theme.colors.dkGreen};
    }
  }

  .slick-dots li.slick-active button:after {
    background-color: ${p => p.theme.colors.xltGreen};   
  }
`

const ImageWrapper = styled.div`
  position: relative; 
  display: block !important;
  width: 100%;
  margin: auto;

  .slick-slide & {
    height: 100%;
    .gatsby-image-wrapper [data-main-image] {
      transition: none;
      opacity: 1;
    }
    .gatsby-image-wrapper {
      height: 100%;
    }
  }

  & > div {
    margin: 0 0 15px 0;

    ${p => p.theme.media.minWidth('tablet')} {
      margin: 0 0 25px 0;
    }   
  }

  &:last-child > div {
    margin: 0;
  }

`

const Close = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 10px;  
  z-index: 2;

  ${p => p.theme.media.minWidth('tablet')} {
    right: 20px;
    top: 20px;
  }

  i {
    font-size: 24px;
    color: ${p => p.theme.colors.xxdkGreen};

    ${p => p.theme.media.minWidth('tablet')} {
      font-size: 18px;
    }
  }  
`

const MediaModal = ({ open, index=0, images, onCloseClick }) => {
  const modalRef = useRef()
  const scrollRef = useRef()
  const imageRefs = useRef([])
  const carouselRef = useRef()
  
  imageRefs.current = images.map(
    (img, i) => imageRefs.current[i] ?? React.createRef()
  )

  const settings = {
    arrows: false,
    dots: true,
    adaptiveHeight: true,
    vertical: true,
    draggable: false,
    initialSlide: index,
    responsive: [
      {
        breakpoint: 768,
        initialSlide: index,
        settings: {
          vertical: false
        }
      }
    ]
  }

  useEffect(() => {
    if ( !carouselRef.current ) return
    
    carouselRef.current.slickGoTo(index, true)
  }, [index])
  
  useEffect(() => {
    const tl = gsap.timeline()

    if ( open ) {
      tl.fromTo(modalRef.current, .5, {
        autoAlpha: 0,
        y: 15
      }, {
        autoAlpha: 1,
        y: 0,
        ease: 'power4.out'
      })
    } else {
      tl.to(modalRef.current, 0, {
        autoAlpha: 0
      })
    }
  }, [open])

  useEffect(() => {
    if ( !scrollRef.current ) return
    
    const image = imageRefs.current[index].current
    scrollRef.current.scroll(0, image.offsetTop)
  }, [index])
    
  const renderScroll = () => (
    <Scroll ref={scrollRef}>
      {images.map((data, i) => {
         const image = getImage(
           data
         )
         
         return (
           <ImageWrapper key={i} ref={imageRefs.current[i]}>
             <GatsbyImage image={image} alt='' />
           </ImageWrapper>
         )
       })}
    </Scroll>
  )
  
  const renderSlider = () => (
    <Carousel {...settings} ref={carouselRef}>
      {images.map((data, i) => {
         const image = getImage(
           data
         )
         
         return (
           <ImageWrapper key={i} ref={imageRefs.current[i]}>
             <GatsbyImage image={image} alt='' />
           </ImageWrapper>
         )
       })}
    </Carousel>
  )
  
  return (
    <Modal ref={modalRef}>
      <Close onClick={onCloseClick}>
        <i className='icon-close'></i>
      </Close>
      <Breakpoint tablet up>
        {renderScroll()}
      </Breakpoint>
      <Breakpoint mobile-large down>
        {renderSlider()}
      </Breakpoint>
    </Modal>
  )
}

export default MediaModal
