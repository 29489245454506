import React, { useRef } from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Slider from 'react-slick'

const Carousel = styled(Slider)`
  margin: 0 0 20px 0;

  .slick-slide {
    border: none !important;
  }

  .slick-dots {
    ${p => p.theme.mixins.hCenter('absolute')};
    bottom: 20px;
    margin: 0;
    padding: 0;
    list-style: none;

    ${p => p.theme.media.minWidth('tablet')} {
      left: 30px;
      transform: none;
    }
  }  

  .slick-dots li {
    display: inline-block;
    margin: 0px;

    ${p => p.theme.media.minWidth('tablet')} {
      display: block;
    }
  }

  .slick-dots li button {
    position: relative;
    padding: 7px;
    display: block;    
    font-size: 0;
    color: transparent;

    &:after {
      display: block;
      content: '';
      width: 4px;
      height: 4px;
      background-color: ${p => p.theme.colors.dkGreen};
    }
  }

  .slick-dots li.slick-active button:after {
    background-color: ${p => p.theme.colors.xltGreen};   
  }
`

const ImageWrapper = styled.div`
  display: block !important;
  position: relative;
  padding-top: ${(906/720) * 100}%;
  cursor: pointer;
  background-color: ${p => p.theme.colors.ltGreen}; 

  .gatsby-image-wrapper [data-main-image] {
    transition: none;
    opacity: 1;
  }

  .gatsby-image-wrapper {
    ${p => p.theme.mixins.fill('absolute')};
  }
`

const MediaCarousel = ({ images, onImageClick, isGallery }) => {
  const slider = useRef()  
  
  const settings = {
    arrows: false,
    dots: true,
    adaptiveHeight: true,
    vertical: true,
    draggable: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          vertical: false
        }
      }
    ]
  }
  
  return (
    isGallery 
      ? <Carousel {...settings} ref={slider}>
          {images.map((data, i) => {

            const image = getImage(
              data
            )

            return (
              <ImageWrapper key={i} onClick={() => onImageClick(i)}>
                <GatsbyImage image={image} alt='' />
              </ImageWrapper>
            )
          })}
        </Carousel>
      : <>
          {images.map((data, i) => {
            const image = getImage(
              data
            )

            return (
              <ImageWrapper key={i} onClick={() => onImageClick(i)}>
                <GatsbyImage image={image} alt='' />
              </ImageWrapper>
            )
          })}
        </>
  )
}

export default MediaCarousel
