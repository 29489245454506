import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import gsap from 'gsap'

import ProductCard from 'components/product/Card'

import usePrevious from 'hooks/usePrevious'

import {
  Container,
  Row,
  Col
} from 'components/Grid'

const UpsellWrapper = styled.div`
  margin-top: 20px;
`

const Tabs = styled.div`
  display: flex;
  height: 40px;
  margin: 0 0 20px 0;
  border-top: solid 1px ${p => p.theme.colors.ltGreen};
  border-bottom: solid 1px ${p => p.theme.colors.ltGreen};

  ${p => p.theme.media.minWidth('tablet')} {
    margin: 0 0 40px 0;
  }  
`

const Tab = styled.button`
  font-size: 13px;
  height: 100%;
  padding: 10px 20px;
  border-right: solid 1px ${p => p.theme.colors.ltGreen};
  background-color: ${p => p.active ? p.theme.colors.ltGreen : 'transparent'};
  transition: background-color 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
  flex: 1;
  white-space: nowrap;

  &:last-child {
    border-right: none;
  }

  &:first-child:last-child {
    flex: 0 1 auto;
  }

  &:hover {
    background-color: ${p => p.theme.colors.ltGreen};
  }

  ${p => p.theme.media.minWidth('tablet')} {
    font-size: 16px;
    flex: 0 1 auto;
  }  
`

const ProductContainer = styled.div`
  position: relative;
  overflow: hidden;
`

const ProductRow = styled(Row)`
  position: ${p => p.active ? 'relative': 'absolute'};
  top: 0;     
  opacity: 0;
  visibility: hidden;
`

const upsells = ['curated', 'paired', 'recommended']

const upsellTitles = {
  curated: 'Curated for You',
  paired: 'Wear With',
  recommended: 'You May Also Like'  
}

const Upsell = ( props ) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const prevTabIndex = usePrevious(activeTabIndex)
  const rowRefs = useRef([])

  rowRefs.current = upsells.map(
    (key, i) => rowRefs.current[i] ?? React.createRef()
  )
  
  useEffect(() => {
    const tl = gsap.timeline()
    const fromRow = rowRefs.current[prevTabIndex]
    const toRow = rowRefs.current[activeTabIndex]

    if ( fromRow && fromRow.current )
      tl.to(fromRow.current, .3, {
        autoAlpha: 0,
        ease: 'power4.out'
      })

    if ( toRow && toRow.current ) {
      tl.set(toRow.current, {
        autoAlpha: 1
      })
      
      tl.set(toRow.current.children, {
        autoAlpha: 0
      })
      
      tl.to(toRow.current.children, .75, {
        autoAlpha: 1,
        stagger: .06
      })
    }    
  }, [prevTabIndex, activeTabIndex])

  const onTabClick = ( index ) => {
    setActiveTabIndex(index) 
  }

  const upsellGroups = upsells.map((upsell, i) => {
    const products = props[upsell]
    if ( products.length === 0 ) return null
    
    return {
      title: upsellTitles[upsell],
      products
    }
  }).filter(g => !!g)

  
  const renderProducts = products => (
    products.map((product, i) => (
      <Col mb={12} tb={6} dk={6} key={i}>
        <ProductCard {...product} key={i} />
      </Col>
    ))
  )
  
  return (
    upsellGroups.length ? (
      <UpsellWrapper>
        <Tabs>
          {upsellGroups.map((group, i) => (
             <Tab active={activeTabIndex === i}
                  onClick={() => onTabClick(i)}
                  key={i}>
               {group.title}
             </Tab>
           ))}
        </Tabs>
        
        <Container>
          <ProductContainer>
            {upsellGroups.map((group, i) => (
               <ProductRow
                   ref={rowRefs.current[i]}
                   active={activeTabIndex === i}
                   key={i}>
                 {renderProducts(group.products)}
               </ProductRow>
             ))}
          </ProductContainer>
        </Container>
      </UpsellWrapper>
    ) : null
  )
}

export default Upsell
